import PropTypes from 'prop-types'
import React from 'react'

// Layout
import Layout from 'layout'

// Page Components
import Hero from './components/hero'
import Content from './components/content'

function Homepage({ data }) {
  return (
    <Layout>
      <Hero />
      <Content />
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Homepage
