import React from 'react'
import { Link } from 'gatsby'
import { Section, Columns, Box } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'

function About() {
  const handleFormSubmit = async (e) => {
    console.log('Submitting')
    e.preventDefault()
    const myForm = document.getElementById('newsletter-form')
    const formData = new FormData(myForm)
    document.querySelector('#form-submit-button').innerText = 'Sending...'
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted')
        alert('Form successfully submitted')
      })
      .catch((error) => alert(error))
  }

  return (
    <Section className="has-background-white-ter my-0 pt-0 content">
      <Columns>
        <Columns.Column className="is-11 ">
          <div class="columns is-variable is-4 is-flex-desktop is-align-items-center-desktop mt-6">
            <div class="column is-4-desktop is-12-mobile is-offset-1 has-text-centered-mobile">
              <h3 class="has-text-weight-light is-size-2 is-size-3-tablet is-size-3-mobile">
                Benefits of subscribing to
                <br />
                our newsletter
              </h3>
            </div>
            <div class="column is-6-desktop is-12-mobile">
              <ul>
                <li>
                  <span class="has-text-weight-semibold">
                    Expertise You Can Trust:
                  </span>{' '}
                  Backed by years of experience, we are dedicated to empowering
                  app and business owners with the knowledge they need to
                  succeed.
                </li>
                <li>
                  <span class="has-text-weight-semibold">
                    Community Connection:
                  </span>{' '}
                  Join a thriving community of business leaders, entrepreneurs,
                  and tech enthusiasts and stay updated of the latest trends and
                  events.
                </li>
                <li>
                  <span class="has-text-weight-semibold">
                    Fortnightly Goodness:
                  </span>{' '}
                  No spam, just quality content delivered to your inbox every
                  two weeks. We respect your time and strive to make every
                  newsletter a valuable investment.
                </li>
              </ul>
            </div>
          </div>

          <div class="divider mt-6" />

          <div class="columns is-variable is-4 is-flex mob-flex-reverse	is-align-items-center">
            <div class="column is-5-desktop is-12-mobile is-offset-1 has-text-centered-mobile">
              <h3 class=" has-text-weight-light is-size-2 is-size-3-tablet is-size-3-mobile">
                Be the first to know <br /> about new resources
                <br />
                to make your app grow.
              </h3>
              <p>
                Templates, reports and guidelines to help you create effective
                mobile apps, or improve your current one. <br />
                <br /> We've done the research so you can focus on building
                amazing apps.
              </p>
            </div>
            <div class="column is-6-desktop is-12-mobile has-text-centered-mobile">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1705577315-pocketworks-resources.png"
                alt="Pocketworks Resources"
                height={720}
              />
            </div>
          </div>
          <div class="divider mb-6" />
          <div class="columns is-variable is-4 is-flex-desktop is-align-items-center">
            <div class="column is-3-desktop is-12-mobile is-offset-1 has-text-centered-mobile">
              <StaticImage
                className=""
                src="https://www.datocms-assets.com/20955/1705577786-pocketworks-growth-strategies.png"
                alt="Pocketworks Testimonial Client"
                height={343}
              />
            </div>
            <div class="column is-6-desktop is-12-mobile is-offset-1 has-text-centered-mobile">
              <h3 class="has-text-weight-light is-size-2 is-size-3-tablet is-size-3-mobile">
                Growth strategies
                <br />
                that work
              </h3>
              <p>
                Explore effective growth strategies and learn how to navigate
                the ever-evolving app ecosystem.
                <br />
                <br />
                Unlock the secrets to understanding your audience, market
                trends, and competitor landscapes.
              </p>
            </div>
          </div>
          <div class="divider my-6" />
          <div class="columns is-variable is-4 is-flex-desktop is-align-items-center">
            <div class="column is-8-desktop is-12-mobile is-offset-2 has-text-centered">
              <h3 class="has-text-weight-light is-size-2 is-size-3-tablet is-size-3-mobile">
                Stay up-to-date and get tech
                <br /> insights from our team
              </h3>
              <p>
                Learn from our success stories and challenges we've faced
                alongside our clients. Get Insider Tips from our team, we spill
                the beans to keep you ahead of the curve. Monthly Favourites:
                Apps, Reads and Podcasts. Find out about Tech Events in UK and
                around Europe.
              </p>
              <form
                name="newsletter-form'"
                id="newsletter-form'"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="newsletter-form" />

                <section className="card has-background-white-ter py-5 px-3 mb-6 is-size-6 has-text-centered mt-6 mx-3 has-border rounded ">
                  <div class="columns is-variable is-4 is-flex mob-flex-reverse	is-align-items-center">
                    <div class="column is-8-desktop is-12-mobile is-offset-2 mb-2">
                      <h2 class="has-text-center mt-2">
                        Get app advice straight to your inbox
                      </h2>
                      <div class="columns is-variable is-4 is-flex mob-flex-reverse	is-align-items-center">
                        <div class="column is-10-desktop is-12-mobile is-offset-1">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input is-rounded"
                                type="text"
                                name="name"
                                placeholder="Name*"
                                required
                              />
                            </div>
                          </div>

                          <div className="field">
                            <div className="control">
                              <input
                                className="input is-rounded"
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                              />
                            </div>
                          </div>
                          <label>
                            <span class="is-orange">You're in control</span>
                            <br />
                            You can unsubscribe at anytime and we do not sell
                            your data to anyone
                          </label>
                          <div>
                            <button
                              className="button is-primary is-leafy is-medium is-fullwidth mt-5"
                              id="form-submit-button"
                              type="submit"
                            >
                              Get Started
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </Columns.Column>
      </Columns>
    </Section>
  )
}

export default About
