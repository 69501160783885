/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Container, Hero, Columns, Section } from 'react-bulma-components'
import { Link as ScrollLink } from 'react-scroll'

// Components
import SiteNav from 'components/navbar'
import Separator from 'components/separator'
import LogoSvg from './logo.svg'

function HeroComponent() {
  const handleFormSubmit = async (e) => {
    console.log('Submitting')
    e.preventDefault()
    const myForm = document.getElementById('newsletter-form')
    const formData = new FormData(myForm)
    document.querySelector('#form-submit-button').innerText = 'Sending...'
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted')
        alert('Form successfully submitted')
      })
      .catch((error) => alert(error))
  }

  return (
    <Section className="has-background-white-ter my-0 pt-0 content">
      <Columns>
        <Columns.Column className="is-12  ">
          <Hero className="bg-orange-pattern-hero bottom-rounded is-small">
            <Hero.Header>
              <SiteNav className="" fullLogo whiteRoundedNav noTopBar />
            </Hero.Header>
            <div className="rectangle-1479" />
            <div className="rectangle-1657" />
            <Hero.Body class="pb-0">
              <Container className="is-widescreen">
                <div className="columns content is-vcentered is-multiline is-centered has-text-left-desktop has-text-centered-tablet has-text-centered-mobile mt-5 pt-5">
                  <Columns.Column className="is-5-desktop is-10-tablet is-10-mobile is-offset-1-mobile">
                    <h2 class="is-size-2-desktop is-size-3-tablet is-size-3-mobile has-font-weight-100 has-text-white is-size-2-tablet is-size-3-mobile is-top-marginless mt-2 mt-3-mobile ">
                      Get app advice straight to your inbox.
                    </h2>
                    <p className="is-size-5">
                      Join now for a fortnightly dose of insights, expert tips,
                      and industry secrets that will take your app from concept
                      to success.
                    </p>
                  </Columns.Column>

                  <Columns.Column className="is-4-desktop is-6-tablet is-offset-2-desktop is-offset-1-tablet ">
                    <form
                      name="newsletter-form"
                      id="newsletter-form"
                      method="POST"
                      data-netlify="true"
                      netlify-honeypot="bot-field"
                    >
                      <input
                        type="hidden"
                        name="form-name"
                        value="newsletter-form"
                      />

                      <section className="box has-background-white-ter pb-5 mb-6 is-size-6 has-text-centered px-4 m-3 rounded ">
                        <h2 class="has-text-center">
                          Subscribe to the <br /> newsletter
                        </h2>

                        <div className="field">
                          <div className="control">
                            <input
                              className="input is-rounded"
                              type="text"
                              name="name"
                              placeholder="Name*"
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <input
                              className="input is-rounded"
                              type="email"
                              name="email"
                              placeholder="Email"
                              required
                            />
                          </div>
                        </div>
                        <label>
                          <span class="is-orange">You're in control</span>
                          <br />
                          You can unsubscribe at anytime and we do not sell your
                          data to anyone
                        </label>
                        <div>
                          <button
                            className="button is-primary is-leafy is-medium is-fullwidth mt-5"
                            id="form-submit-button"
                            type="submit"
                          >
                            Subscribe
                          </button>
                        </div>
                      </section>
                    </form>
                  </Columns.Column>
                  <div className="column is-12-desktop is-12-widescreen is-12-tablet is-12-mobile ">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1704805967-pocketworks-team.png"
                      alt="ASDA - Pocketworks"
                      placeholder="tracedSVG"
                      height={547}
                    />
                  </div>

                  <Separator className="is-hidden mt-3" rtl />
                </div>
              </Container>
            </Hero.Body>
          </Hero>
        </Columns.Column>
      </Columns>
    </Section>
  )
}

// HeroComponent.propTypes = {
//   featuredMedia: PropTypes.object,
// }

export default HeroComponent
