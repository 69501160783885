/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Newsletter from 'routes/newsletter'

// Meta Component
import Meta from 'components/Meta/Meta'

// Story Route

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export default Newsletter

export const query = graphql`
  query StoryQuery {
    page: datoCmsPage(slug: { eq: "newsletter" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
